import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { LocalizedButton } from "./special.types";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespacesList from "../../../i18n/namespaceList";

export const getCodeButtonContent = (
    props: { options: { displayButton: any | "ICON_LABEL" | "LABEL" | "ICON"; localizedButtonOptions: LocalizedButton[] }; context: CMSProviderProperties },
    type: "REMOVE" | "ADD",
    showLoader = false
) => {
    const {
        options,
        context: { currentLocale, site },
    } = props;
    const { displayButton, localizedButtonOptions } = options;
    const localizedContent = getLocalizedContent({ currentLocale, site, localizedContent: localizedButtonOptions });
    const i18nKey = type === "ADD" ? "searchButtonText" : "removeButtonText";
    const showLabel = displayButton === "ICON_LABEL" || displayButton === "LABEL";
    const showIcon = displayButton === "ICON_LABEL" || displayButton === "ICON";
    const iconClass = classNames({
        "fa": true,
        "fa-plus": type === "ADD",
        "fa-minus": type === "REMOVE",
    });

    return (
        <React.Fragment>
            {showLoader && <FontAwesome name="circle-o-notch" spin />}
            {showIcon && <span className={iconClass} />}
            {displayButton === "ICON_LABEL" && " "}
            <span className="special-label">
                {showLabel && (type === "ADD" && localizedContent?.buttonText ? localizedContent?.buttonText : getI18nLocaleString(namespacesList.dynamicSpecial, i18nKey, currentLocale, site))}
            </span>
        </React.Fragment>
    );
};
