import * as React from "react";

import { FormSpec, localized } from "../../../form-specs";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";

import { DynamicSpecialSearchFacet } from "./SpecialSearchFacet";
import { PageWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    localizedButtonOptions: LocalizedButton[];
    displayButton: "ICON_LABEL" | "LABEL" | "ICON";
}

export interface LocalizedButton {
    locale: string;
    buttonText: string;
}

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamic-special-search-facet-widget",
    name: getI18nLocaleObject(namespaceList.dynamicSpecialSearchFacet, "specialSearchFacetWidget"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicSpecialSearchFacet, "specialSearchFacetWidgets"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            localized({
                                variable: "localizedButtonOptions",
                                tabContent: [
                                    {
                                        variable: "buttonText",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "buttonText"),
                                        default: getI18nLocaleString(namespaceList.dynamicSpecial, "searchButtonText"),
                                        type: "text",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [
                        [
                            {
                                variable: "displayButton",
                                label: getI18nLocaleObject(namespaceList.admin, "style"),
                                type: "select",
                                default: "ICON_LABEL",
                                optionList: [
                                    {
                                        value: "ICON",
                                        label: getI18nLocaleObject(namespaceList.dynamicSpecial, "showOnlyIcon"),
                                    },
                                    {
                                        value: "LABEL",
                                        label: getI18nLocaleObject(namespaceList.dynamicSpecial, "showOnlyLabel"),
                                    },
                                    {
                                        value: "ICON_LABEL",
                                        label: getI18nLocaleObject(namespaceList.dynamicSpecial, "showIconLabel"),
                                    },
                                ],
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const specialSearchFacetWidget: PageWidgetSpec<WidgetOptions> = {
    id: "specialSearchFacetWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.dynamicSpecialSearchFacet, "specialSearchFacetWidget"),
    description: getI18nLocaleObject(namespaceList.dynamicSpecialSearchFacet, "specialSearchFacetWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        localizedButtonOptions: [],
        displayButton: "ICON_LABEL",
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        return <DynamicSpecialSearchFacet dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} />;
    },
};
