import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { Action, ActionType } from "../../../redux/actions";
import { isWidgetHidden, setOpacityOnHide } from "../../../components/utils";
import { useDispatch, useSelector } from "react-redux";

import { ApiContext } from "../../../containers/cmsProvider.types";
import { AvailabilityUtil } from "../../../utils/availability.util";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { dispatchNow } from "../../../utils/redux.util";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getCodeButtonContent } from "../special/specialWidget.util";
import { getI18nLocaleString } from "../../../i18n";
import namespacesList from "../../../i18n/namespaceList";
import { toggleSpecialModal } from "../../../redux/actions/userInterfaceAction";

interface SpecialFacetProps extends DynamicWidgetBaseProps<WidgetOptions> {}

function SpecialSearchFacetWidget(props: SpecialFacetProps) {
    const {
        context: { currentLocale, site },
        options,
    } = props;

    const [specialValue, setSpecialValue] = React.useState<string>("");
    const [addedSpecialStatus, setAddedSpecialStatus] = React.useState<{ specialCode: string; isBookable: boolean }>();
    const [isAddingSpecial, setIsAddingSpecial] = React.useState<boolean>(false);

    const dynamicFilter = useSelector((state: State) => state.dynamicFilter);

    const dispatchAction = useDispatch();

    const onConfirmSpecial = React.useCallback(async () => {
        setAddedSpecialStatus(undefined);
        setIsAddingSpecial(true);
        if (await isSpecialCodeAvailable(props.context, dynamicFilter, specialValue)) {
            setAddedSpecialStatus({ specialCode: specialValue, isBookable: true });
            addSpecialToDynamicFilter({ dispatchAction, specialValue });
        } else {
            setAddedSpecialStatus({ specialCode: specialValue, isBookable: false });
        }
        setIsAddingSpecial(false);
    }, [dispatchAction, specialValue, dynamicFilter]);

    const handleSpecialChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAddedSpecialStatus(undefined);
        setSpecialValue(event.target.value);
    }, []);

    const onRemoveSpecial = React.useCallback(
        (targetSpecialCode: string) => {
            removeSpecialFromDynamicFilter({ dispatchAction, specialValue: targetSpecialCode });
            setSpecialValue("");
        },
        [dispatchAction]
    );

    if (isWidgetHidden(options)) {
        return null;
    }

    return (
        <div className={classNames("special-facet", setOpacityOnHide(options))}>
            <div className="special-facet__input-container">
                <input
                    className={classNames("special-facet__input", { "invalid-special": addedSpecialStatus?.specialCode === specialValue && !addedSpecialStatus?.isBookable })}
                    onChange={handleSpecialChange}
                    type="text"
                    value={specialValue}
                />
                {
                    <a
                        className={classNames({
                            "button": true,
                            "special-facet__button": true,
                            "disabled": !specialValue || specialValue.length < 2 || isAddingSpecial,
                        })}
                        onClick={onConfirmSpecial}
                    >
                        {isAddingSpecial ? <FontAwesome name="circle-o-notch" spin /> : getCodeButtonContent(props, "ADD")}
                    </a>
                }
            </div>
            {addedSpecialStatus?.specialCode === specialValue && !addedSpecialStatus?.isBookable && (
                <p className="special-facet__label special-facet__label--error">
                    <FontAwesome name="times-circle" />
                    {getI18nLocaleString(namespacesList.dynamicSpecial, "promoInvalidLabel", currentLocale, site)}
                </p>
            )}
            {addedSpecialStatus?.specialCode === specialValue && addedSpecialStatus?.isBookable && (
                <p className="special-facet__label special-facet__label--secondary">
                    <FontAwesome name="check" />
                    {getI18nLocaleString(namespacesList.dynamicSpecialSearchFacet, "promoAddedLabel", currentLocale, site)}
                </p>
            )}
        </div>
    );
}

function addSpecialToDynamicFilter(params: { specialValue: string; dispatchAction: Dispatch<Action> }) {
    const { specialValue, dispatchAction } = params;
    dispatchNow<FilterChangeAction>(dispatchAction, {
        type: ActionType.FilterChange,
        filter: dynamicFilterType.addspecialcode,
        payload: { specialcode: [specialValue?.trim()] },
    });
    // Added this set time out to close popup with some delay after showing "Coupon applied successfully" message
    setTimeout(() => {
        dispatchAction(toggleSpecialModal(true));
    }, 1700);
}

function removeSpecialFromDynamicFilter(params: { specialValue: string; dispatchAction: Dispatch<Action> }) {
    const { specialValue, dispatchAction } = params;
    dispatchNow<FilterChangeAction>(dispatchAction, {
        type: ActionType.FilterChange,
        filter: dynamicFilterType.removespecialcode,
        payload: { specialcode: [specialValue?.trim()] },
    });
}

async function isSpecialCodeAvailable(apiContext: ApiContext, dynamicFilter: DynamicFilter, specialValue: string) {
    return !!(
        await AvailabilityUtil.getAvailabilityByDynamicFilter(
            {
                ...dynamicFilter,
                specialcode: [specialValue?.trim()],
                shouldFetchResorts: false,
                shouldFetchSortedUnits: false,
                shouldFetchStayPeriods: false,
                shouldFetchUnitsWithPrice: false,
            },
            {
                customAggregations: [
                    {
                        name: "RESORT_FACET",
                        field: "RESORT_ID",
                        excludeFields: ["RESORT_ID"],
                        type: "FACET",
                        size: 1,
                    },
                ],
            },
            apiContext
        )
    ).availabilityResult.response?.resorts?.length;
}

export const DynamicSpecialSearchFacet = SpecialSearchFacetWidget;
